/*
 * Add color theme colors to the window object
 * so this can be used by the charts and vector maps
 */

const theme = {
  primary: "#3B7DDD",
  secondary: "#6c757d",
  success: "#28a745",
  info: "#17a2b8",
  warning: "#ffc107",
  danger: "#dc3545"
};

// Add theme to the window object
window.theme = theme;